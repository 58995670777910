import home from './home.js'
import login from './login.js'
import personal from './personal.js'
import organ from './organ.js'
import activity from './activity.js'
import common from './common.js'
import message from './message.js'
import news from './news.js'
import chat from './chat.js'
import helpCenter from './help-center.js'
import worksDownload from './works-download.js'
import data from './data.js'
var api = {}

api = Object.assign(api, home, login, organ, personal, activity, common, message, news, chat, helpCenter, worksDownload, data)

// console.log('登录信息',login)
export default api
